<template>
  <div class="iCountUp text-center d-flex justify-center">
    <ICountUp
      v-if="createCount"
      :endVal="endVal"
      :options="computedOptions"
    />
    <span class="iCountUpBox">
      <span class="iCountUptext">
        <slot v-if="createCount"></slot>
      </span>
    </span>
  </div>
</template>

<script type="text/babel">
import ICountUp from "vue-countup-v2";
export default {
  props: {
    startVal: {
      type: Number,
      default: 0,
    },
    endVal: {
      type: Number,
      default: 1000,
    },
    options: {
      type: Object,
    },
    root: {
      type: String,
      default: "main",
    },
  },
  components: {
    ICountUp,
  },
  data() {
    return {
      createCount: false,
      defaultOptions: {
        useEasing: true,
        useGrouping: false,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
        duration: 2,
      },
      observer: null,
      observerOption: {
        root: document.querySelector(this.root),
        rootMargin: "0px",
        threshold: 0.1,
      },
    };
  },
  computed: {
    computedOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
        startVal: this.startVal,
      };
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(
      this.observerCallback,
      this.observerOption
    );
    this.observer.observe(this.$el);
  },
  methods: {
    observerCallback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.createCount = true;
          this.observer.unobserve(this.$el);
        }
      });
    },
  },
};
</script>
